/**
 * @file index.ts
 * @description exports profiler and error interceptor
 */
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ProfilerInterceptor } from './profiler/profiler.interceptor';
import { ErrorInterceptor } from './error/error.interceptor';
import { TokenInterceptor } from './token/token.interceptor';
import { WordpressInterceptor } from './wordpress/token/wordpress.interceptor';

export const HttpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ProfilerInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: WordpressInterceptor, multi: true }
];
