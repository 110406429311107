/**
 * @file app-insights.service.ts
 */
import { Injectable } from '@angular/core';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { UserService } from '../user/user.service';
import { MemberService } from '../member/member.service';

@Injectable({
  providedIn: 'root'
})
export class AppInsightsService {

  appInsights: ApplicationInsights;
  constructor(
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly memberService: MemberService,
  ) {
    const angularPlugin = new AngularPlugin();
    this.appInsights = new ApplicationInsights({
      config: {
        enableAutoRouteTracking: true,
        instrumentationKey: environment.instrumentationKey,
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: { router: this.router }
        }
      }
    });
    this.appInsights.loadAppInsights();
    this.appInsights.context.user.accountId = this.userService.eligibilityVerificationResponse ?.clientId || ''; // clientid
    this.appInsights.context.user.id = this.userService.userName || '';
    this.appInsights.config.isBrowserLinkTrackingEnabled = true;
    // this.appInsights.context.user.isNewUser = appConfig.isFreshLogin;
    this.appInsights.config.autoTrackPageVisitTime = true;
    // this.appInsights.context.location.ip = '';
  }

  getDate(): string {
    var dateToday = new Date();
    return `${dateToday.getFullYear()}/${dateToday.getMonth() + 1}/${dateToday.getDate()}`;
  }

  getName(): string {
    return `${this.userService.member?.firstName ? this.userService.member?.firstName : this.memberService.user?.firstName ? this.memberService.user?.firstName : 'unknown'} ${this.userService.member?.lastName ? this.userService.member?.lastName : this.memberService.user?.lastName ? this.memberService.user?.lastName : 'unknown' }`
  }
  trackPageView(
    name: string,
    additionalProperties?: any,
    uri?: string,
  ): void { // option to call manually
    const userDetails = {
      clientID: this.appInsights.context.user.accountId, userID: this.appInsights.context.user.id, Date: this.getDate()
      , Email: this.memberService ?.user ?.email, IsExistingUser: !this.memberService ?.user ?.isProfileVerified, Name: this.getName()
    };
    const properties = { ...userDetails, ...additionalProperties }
    this.appInsights.trackPageView({ name, uri, properties });
  }

  trackPageViewWithoutClientId(
    name: string,
    properties: { [key: string]: any } = { Platform: 'web', Date: this.getDate() },
    uri?: string
  ): void { // option to call manually
    this.appInsights.trackPageView({ name, uri, properties });
  }

  trackPageViewWithEmailProperties(
    name: string,
    email: string,
    isError: boolean,
    errorMsg?: string,
    properties: { [key: string]: any } = isError ? { Email: email, Platform: 'web', Date: this.getDate(), Error: errorMsg } : { Email: email, Platform: 'web', Date: this.getDate() },
    uri?: string
  ): void { // option to call manually
    this.appInsights.trackPageView({ name, uri, properties });
  }
  trackEvent(
    name: string,
    properties: { [key: string]: any } = {
      clientID: this.appInsights.context.user.accountId, userID: this.appInsights.context.user.id,
      Date: this.getDate(), Email: this.memberService.user.email, IsExistingUser: !this.memberService.user.isProfileVerified, Name: this.getName()
    }
  ): void {
    this.appInsights.trackEvent({ name }, properties);
  }

  trackMetric(
    name: string,
    average: number,
    properties: { [key: string]: any } = { clientID: this.appInsights.context.user.accountId, userID: this.appInsights.context.user.id }
  ): void {
    this.appInsights.trackMetric({ name, average }, properties);
  }

  trackException(exception: Error, severityLevel?: number): void {
    this.appInsights.trackException({ exception, severityLevel });
  }

  trackTrace(
    message: string,
    properties: { [key: string]: any } = { clientID: this.appInsights.context.user.accountId, userID: this.appInsights.context.user.id }
  ): void {
    this.appInsights.trackTrace({ message }, properties);
  }

}
