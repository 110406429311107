
/**
 * @file environnement.dev.ts
 */
export const environment = {
  envName: 'dev',
  production: false,
  hostURL: 'https://api-dev.myempirxhealth.com/api',
  langHostURL: `https://api-dev.myempirxhealth.com/api`,
  b2cSignUpSignInFlow: 'https://ehmpb2cdev.b2clogin.com/ehmpb2cdev.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SignUpSignIn&client_id=5dc671b0-988f-4abd-a1eb-b2bdcd59042c&nonce=defaultNonce&redirect_uri=' + window.location.origin + '&scope=openid&response_type=id_token&prompt=login',
  b2cForgotPassFlow: 'https://ehmpb2cdev.b2clogin.com/ehmpb2cdev.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_PasswordReset&client_id=5dc671b0-988f-4abd-a1eb-b2bdcd59042c&nonce=defaultNonce&redirect_uri=' + window.location.origin + '&scope=openid&response_type=id_token&prompt=login',
  googleApiKey: 'AIzaSyBfo9a3aDoFj_C9TyCowJa2g7Dq7pVpkSI',
  instrumentationKey: '14b8ce80-3fe4-4035-ac26-ffb46de240ce',
  getDocuments: 'https://api-dev.myempirxhealth.com/api/CMSDocker/index.php/wp-json/wp/v2/pages/pdf-download',
  wpApiToken: 'hut9po9b8rt6miuzcwkap4xavmo21ukp4ncazvn8'
};
