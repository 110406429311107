/**
 * @file auth.guard.ts
 */
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { environment } from '../../../../environments/environment';
import { MultilingualService } from '../../services/multilingual/multilingual.service';

@Injectable({
  providedIn: 'root'
})
export class AzureB2CAuthGuard implements CanActivate {

  constructor(
    private readonly authService: AuthService,
    private readonly multilingualService: MultilingualService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const fragment = route.fragment!;
    if (fragment) {
      const idToken = new URLSearchParams(fragment).get('id_token') || '';
      const ssoToken = new URLSearchParams(fragment).get('sso_token') || '';
      const errorDescription = new URLSearchParams(fragment).get('error_description');
      if (idToken || ssoToken) {
        // handle token
        this.authService.login(idToken, ssoToken);
        this.multilingualService.getLangMap(this.multilingualService.getUserLanguage(), true);
        //this.appInsightsService.trackPageView(AppCenterAnalytics.LOGIN_SUCCESSFUL);
        return true;
      } else {
        if (errorDescription?.split(':').includes('AADB2C90118')) {
          // user forgotpassword flow
          this.resetPass();
        } else {
          this.login();
        }
        return false;
      }
    } else {
      if (this.authService.isAuthorizedByAzureB2c || this.authService.isAuthorizedBySSO) {
        this.multilingualService.getLangMap(this.multilingualService.getUserLanguage(), true);
        return true;
      } else {
        this.login();
        return false;
      }
    }
  }

  public resetPass(): void {
    window.location.replace(environment.b2cForgotPassFlow);
  }

  public login(): void {
    //this.appInsightsService.trackPageView(AppCenterAnalytics.LOGIN_FORM_VIEWED);
    window.location.replace(environment.b2cSignUpSignInFlow);
  }
}
