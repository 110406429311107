/**
 * @file app.component.ts
 * @description a root app module
 */
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxMaskModule } from 'ngx-mask';

import { AppComponent } from './app.component';
import { HttpInterceptorProviders } from './interceptors';
import { ErrorHandlerService } from './services/error-handler/error-handler.service';
import { ErrorManagerService } from './core/services/error';
import { ErrorDialogComponent } from './core/components/error/error-dialog.component';
import { LoaderComponent } from './core/components/loader/loader.component';
import { LogService } from './core/services/log/log.service';
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './core/modules/material.module';
import { MultilingualService } from './core/services/multilingual/multilingual.service';
import { ToastrComponent } from './core/components/toastr/toastr.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../environments/environment';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { LegacyLoginComponent } from './legacy-login/legacy-login.component';
import { PreCompleteProfileComponent } from './pre-complete-profile/pre-complete-profile.component';
import { CompleteProfileComponent } from './complete-profile/complete-profile.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ForgotUsernameComponent } from './forgot-username/forgot-username.component';
import { SsoErrorComponent } from './sso-error/sso-error.component';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';

@NgModule({
    declarations: [
        AppComponent,
        ErrorDialogComponent,
        LoaderComponent,
        ToastrComponent,
        TermsOfUseComponent,
        PrivacyPolicyComponent,
        LegacyLoginComponent,
        PreCompleteProfileComponent,
        CompleteProfileComponent,
        ForgotUsernameComponent,
        SsoErrorComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgxMaskModule.forRoot(),
        MaterialModule,
        AgmCoreModule.forRoot({
            apiKey: environment.googleApiKey
        })
    ],
    providers: [
        HttpClientModule,
        LogService,
        HttpInterceptorProviders,
        ErrorManagerService,
        ErrorHandlerService,
        MultilingualService,
        {
            provide: ErrorHandler,
            useClass: ApplicationinsightsAngularpluginErrorService
        },
        {
            provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: {disabled: true}
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
