/**
 * @file services.constants.ts
 */
export const APIConstants = {
  BENEFITS_SERVICE: {
    CapsDeductibleAccumulations: '/Benecard/benefits-service/benefits-service/rest/member/caps-deductibles-accumulations',
    CopaySchedule: '/Benecard/benefits-service/benefits-service/rest/copay-schedule',
    Exclusions: '/Benecard/benefits-service/benefits-service/rest/exclusions',
    PreferredMedicationList: '/Benecard/benefits-service/benefits-service/rest/preferred-medication-list',
    PreferredMedicationListById: '/Benecard/benefits-service/benefits-service/rest/preferred-medication-list-file'
  },
  USER_MGMT_SERVICES: {
    GET_CONTACT_INFO: '/Benecard/user-mgmt-service/user-mgmt-service/rest/contact-information',
    GET_ELIGIBILITY_VERIFICATION: '/Benecard/user-mgmt-service/user-mgmt-service/rest/eligibility-verification-username',
    GET_ELIGIBILITY_VERIFICATION_SSO: '/Benecard/user-mgmt-service/user-mgmt-service/rest/eligibility-verification-sso',
    GET_NOTIFICATION_SETTINGS: '/Benecard/user-mgmt-service/user-mgmt-service/rest/notification-settings',
    GET_PHI_SETTINGS: '/Benecard/user-mgmt-service/user-mgmt-service/rest/phi-settings',
    PUT_NOTIFICATION_SETTINGS: '/Benecard/user-mgmt-service/user-mgmt-service/rest/notification-settings',
    POST_REGISTRATION: '/Benecard/user-mgmt-service/user-mgmt-service/rest/registration',
    PUT_CONTACT_INFO: '/Benecard/user-mgmt-service/user-mgmt-service/rest/contact-information',
    PUT_PHI_SETTINGS: '/Benecard/user-mgmt-service/rest/phi-settings'
  },
  MAIL_ORDER_ACCOUNT_SERVICE: {
    GET_MAIL_ACCOUNT_BAL: '/Benecard/mail-order-account/mail-order-account/rest/balance'
  },
  MAIL_ORDER_REFILLS_SERVICES: {
    GET_ORDERS: '/Benecard/mail-order/mail-order/rest/orders/search',
    POST_CREATE_ORDERS: '/Benecard/mail-order-refills/mail-order-refills/rest/request-refills',
    GET_PAYMENT_CARDS: '/Benecard/mail-order-credit-card/mail-order-credit-card/rest/payment-cards',
    GET_PRESCIRPTIONS: '/Benecard/mail-order-refills/mail-order-refills/rest/prescriptions',
    GER_SHIPPING_ADDRESS: '/Benecard/mail-order-shipping-address/mail-order-shipping-address/rest/shipping-addresses',
    DELETE_SHIPPING_ADDRESS: '/Benecard/mail-order-shipping-address/mail-order-shipping-address/rest/shipping-addresses'
  },
  MEMBER_SERVICES: {
    GET_DRUG_PRICING: '/Benecard/member-service/member-service/rest/drug-pricing',
    GET_EOB: '/Benecard/member-service/member-service/member-service/rest/explanation-of-benefits',
    GET_FAMILY_COVERAGE: '/Benecard/member-service/member-service/rest/family-coverage',
    GET_MEDICATION_HISTORY: '/Benecard/member-service/member-service/rest/medication-history',
    GET_PHARMACY_SEARCH: '/Benecard/member-service/member-service/rest/pharmacies',
    GET_TEMP_CARD: '/Benecard/member-service/member-service/rest/temp-id-card',
    EOB: '/Benecard/member-service/member-service/rest/explanation-of-benefits',
    GET_DETAILS: '/Member/GetMemberDetails',
    GET_SSO_MEMBER_DETAILS: '/Member/GetSsoMemberDetails',
    UPDATE_DETAILS: '/Member/UpdateMemberDetail',
    UPDATE_CARDID: '/Member/UpdateMemberCardId',
    SEND_OTP: '/Member/SendOTP',
    VERIFY_OTP: '/Member/VerifyOTP',
    UPDATE_SSO_USER_DETAILS: '/Member/UpdateSsoMemberDetail',
    COMPLETE_PROFILE: '/SSOAuth/Register',
    GET_PHI_SETTINGS: '/Benecard/user-mgmt-service/user-mgmt-service/rest/phi-settings',
    UPDATE_PHI_SETTINGS: '/Benecard/user-mgmt-service/user-mgmt-service/rest/phi-settings',
    NOTIFICATION_SETTINGS: '/Benecard/user-mgmt-service/user-mgmt-service/rest/notification-settings',
    SEND_EMAIL: '/Member/EmailUserName',
    GET_USER_NAME: '/member/getusername',
    GET_PRESCRIPTION_PDF: '/member/PrescriptionPDF',
    GET_DRUG_PDF: '/member/DrugPDF'
  },
  CLAIM_SERVICE: {
    GET_CLAIM_ID: '/Benecard/claims-service/claims-service/rest/claim',
    GET_CLAIMS: '/Benecard/claims-service/claims-service/rest/claims',
    GET_CLINICAL_REVIEW: '/Benecard/clinical-service/clinical-service/rest/member/clinical-review-statuses'
  },
  DRUG_SERVICE: {
    GET_DRUG_DETAILS: '/Benecard/drug-service/drug-service/rest/drug',
    GET_INTERACTIONS: '/Benecard/drug-service/drug-service/rest/interactions',
    SEARCH_DRUG: '/Benecard/drug-service/drug-service/rest/drugs'
  },
  FORMS_URL: {
    AUTHORIZATION_PHI: 'https://customuicomponents.blob.core.windows.net/public-forms-pdf/EmpiRx_Authorization_PHI.vF.pdf',
    DMR_FORM: 'https://customuicomponents.blob.core.windows.net/public-forms-pdf/EmpiRX_DMR_Form.vF.pdf',
    MAIL: 'https://customuicomponents.blob.core.windows.net/public-forms-pdf/EmpiRx_Mail.pdf'
  }
};
