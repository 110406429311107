/**
 * @file auth.service.ts
 */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '../token/token.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private readonly router: Router,
    private readonly tokenService: TokenService,
  ) { }

  public login(idToken: string, ssoToken: string): void {
    this.tokenService.setAzureIdToken(idToken);
    this.tokenService.setSSOToken(ssoToken);
    this.router.navigate(['/main']);
  }

  public loginWithBenecard(accessToken: string): void {
    this.tokenService.setBenecardAccessToken(accessToken);
    this.router.navigate(['/main']);
  }

  public logout(): void {
    localStorage.clear();
    window.location.replace(environment.b2cSignUpSignInFlow);
  }

  get isAuthorizedByAzureB2c(): boolean {
    return !!this.tokenService.azureIdToken;
  }

  get isAuthorizedBySSO(): boolean {
    return !!this.tokenService.ssoToken;
  }

  get isAuthorizedByBenecard(): boolean {
    return !!this.tokenService.benecardAccessToken;
  }

}
