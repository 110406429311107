/**
 * @file wordpress.interceptor.ts
 */
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppConstants } from 'src/app/app.constants';

@Injectable()
export class WordpressInterceptor implements HttpInterceptor {

  constructor(
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes(`${environment.hostURL}/CMSDocker/index.php`)) {
      request = request.clone({
        setHeaders: {
          'api-token': `${environment.wpApiToken}`,
        }
      });
    }
    return next.handle(request);
  }
}
