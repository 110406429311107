/**
 * @file app.constants.ts
 * @description Constants for app module
 */
export class AppConstants {
  public static readonly ERROR = {
    MESSAGES: {
      GENERIC: 'We ran into an unexpected error. Please try again.',
      FATAL: 'We ran into an unexpected error. Please refresh the application.'
    }
  };

  public static readonly LANGUAGE = {
    DEFAULT: 'en',
    ENGLISH: 'en',
    SPANISH: 'es'
  };

  public static readonly TEXT_SIZE = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large'
  };

  public static readonly LOADER = {
    DEFAULT_MESSAGE: 'Loading'
  };

  public static readonly DOCKER_CONFIG_KEYS = {
    DEMO_CLIENT: 'DEMO_CLIENT',
    HIDE_OUT_OF_NETWORK_PHARMACIES: 'HIDE_OUT_OF_NETWORK_PHARMACIES',
    DISABLE_TERMED_USER_AFTER: 'DISABLE_TERMED_USER_AFTER',
    SHOW_MAINTENANCE_DOWNTIME_MESSAGE: 'SHOW_MAINTENANCE_DOWNTIME_MESSAGE'
  };

  public static WORDPRESS_API_TOKEN: 'hut9po9b8rt6miuzcwkap4xavmo21ukp4ncazvn8'
}

export enum MEMBER_PORTAL_USER_TYPES {
  NORMAl = 0,
  SSO,
  GUEST
}
