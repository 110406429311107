export const LABELS_ES = {
    // forgot username translations
    FORGOT_USERNAME_TITLE: 'Olvidó su nombre de usuario',
    FORGOT_USERNAME_ERROR_MSG: 'Este correo electrónico no es el correo electrónico registrado en su perfil. Comuníquese con el servicio de atención al cliente al 866-415-0222 o al 857-241-2439 o intente nuevamente con su dirección de correo electrónico registrada.',
    FORGOT_USERNAME_SUCCESS_MSG: 'Hemos enviado con éxito el nombre de usuario a su dirección de correo electrónico registrada. Intente iniciar sesión con ese nombre de usuario.',
    FORGOT_USERNAME_OK: 'DE ACUERDO',
    FORGOT_USERNAME_EMAIL_ADDRESS: 'Dirección de correo electrónico registrado',
    FORGOT_USERNAME_EMAIL_ADDRESS_ERROR: 'Por favor ingrese una dirección de correo electrónico válida',
    FORGOT_USERNAME_EMAIL_ADDRESS_REQUIRED: 'Por favor ingrese la dirección de correo electrónico',
    FORGOT_USERNAME_SUBMIT: 'Entregar'
}