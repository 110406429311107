<section class="custom-page-width auto-margin">
  <div class="header-inner">
    <div class="static-brand">
      <a
        href="https://www.empirxhealth.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="../../assets/images/logo.svg" alt="EmpiRx" class="brand"
      /></a>
    </div>
    <h3 class="static-title">Terms of Use</h3>
  </div>
  <div class="custom-box padding-box mb-3 d-inline-block full-width">
    <h4 class="text-tertiary text-w-700 uppercase title-10">TERMS OF USE</h4>
    <p>
      These Terms of Use apply to registered users who use the EmpiRx Health
      member portal (the “Portal”) available through
      <a href="http://www.EmpiRxHealth.com">www.EmpiRxHealth.com</a> (the
      “Website”). The Website is provided to you as a service to provide
      information about our company and, the Portal is a registered user-only
      account portal that enables Users to access their personal account
      information, their pharmacy benefit, management of their User account and
      access to mail order pharmacy services.
    </p>
    <p>
      “Users” are persons utilizing the Website and include members, their
      dependent spouses or partners, their dependent children over the age of 18
      or their representatives. “Registered Users” are eligible individuals who
      have registered for use of the Portal. If you are a dependent under the
      subscriber member but are not the spouse or partner of the subscriber
      member or the dependent child of the subscriber member over the age of 18
      (or younger if required by applicable law), you are not eligible to access
      the Portal.
    </p>
    <p>
      EmpiRx Health is a pharmacy benefit manager and not a pharmacy or health
      plan. You have access to the Portal because the health plan providing your
      prescription drug benefits has contracted with EmpiRx Health to manage the
      drug benefit. All drug coverage rules, copays, prior authorization, step
      therapy and formulary requirements are decided by your health plan.
    </p>
    <p>
      <strong>CONSENT </strong><br />
      These Terms of Use are between a User of any portion of the Website and
      Portal (“you” or “your”) and EmpiRx Health, the pharmacy benefit manager
      of your prescription benefit plan (“we”, “us” or “our”).
    </p>
    <p>
      You are deemed to have assented to these Terms of Use when you use any
      available page of the Website or Portal; each time you access the Website;
      and each time you use your login credentials to access the Portal portion
      of the Website. You are deemed to have accepted these Terms of Use each
      time you use non-login Website functionality to manage aspects of your
      benefit or utilize the mail order pharmacy. By registering for or
      otherwise accessing or using the Website, you acknowledge that you have
      read, understand, and agree to be legally bound by these Terms of Use.
    </p>
    <p><strong>UPDATES </strong></p>
    <p>
      We may modify or amend the Website or Portal at any time and remove or add
      new features or functionality. Access to and use of these features will
      remain subject to these Terms of Use and the Privacy Statement, each as
      may be amended from time to time. These Terms of Use and the
      <a routerLink="/privacy-policy" target="_blank">Privacy Statement</a> are
      available during registration for the Website and on various pages of the
      Website.
    </p>
    <p>
      We will provide an advance notice of a major change prior to your access
      of any portion of the Website for which registration is required. For
      example, we may (i) require that you reaccept the updated version of the
      web policies, (ii) send an electronic notification advising of the update
      to the web policies, (iii) include a notice on the Website viewable
      without login advising of the update to the web policies, and/or (iv)
      advise of the updated web policies during a phone call. We do not
      ordinarily provide advance notice of a minor change.
    </p>
    <p>
      It is important that you check these Terms of Use every time you visit the
      Website or Portal. Your use of the Website and/or Portal after the Terms
      of Use have been updated (and after advance notice of certain major
      changes), indicates your agreement and acceptance of the updated version
      of the Terms of Use and the
      <a routerLink="/privacy-policy" target="_blank">Privacy Statement</a>,
      including the modifications made as of the date of your use.
    </p>
    <p><strong>PRIVACY STATEMENT </strong></p>
    <p>
      You agree to the
      <a routerLink="/privacy-policy" target="_blank">Privacy Statement</a>
      (“Privacy Statement”), which is incorporated by reference in these Terms
      of Use.
    </p>
    <p><strong>USER OBLIGATIONS </strong></p>
    <p>
      You are required to comply with all applicable laws in connection with
      your use of the Website and the Portal. As a condition of your use of the
      Website and Portal, you agree that you will not use the Website or Portal
      for any purpose that is unlawful or prohibited by these Terms of Use. You
      agree that you will only provide information during registration and in
      connection with any and all other uses of the Website that is true and
      accurate and is not false, misleading, or otherwise an impersonation of
      any person or entity.
    </p>
    <p>
      <strong
        >WEBSITE AND PORTAL CONTENT AND INTELLECTUAL PROPERTY RIGHTS
      </strong>
    </p>
    <p>
      The Website and Portal may contain text, images, software (including
      images or files incorporated in or generated by the software or data
      accompanying such software), photographs, video, graphics, audio,
      features, data, designs, images, and other such similar content
      (collectively “Website Content”). Unless otherwise expressly identified,
      Website Content is owned by us or by our third-party licensors. The
      Website Content is protected by United States and international copyright,
      trademark, and other laws. You may browse the Website and download Website
      Content solely for your personal use, provided you keep intact all
      copyright and other proprietary notices. Except as expressly permitted,
      you may not modify, copy, reproduce, republish, upload, post, transmit,
      hyperlink to or from, or distribute in any way Website Content from this
      Website, including code and software underlying this Website, nor may you
      sell, transfer, or otherwise use the Website or the Website Content in
      commerce or for any public or commercial endeavor without our prior and
      express written consent. We may in our sole discretion make changes to the
      Website Content at any time without notice.
    </p>
    <p><strong>SMS TEXT COMMUNICATIONS </strong></p>
    <p>
      We support SMS texting communications in accordance with the communication
      preferences that are available to you and for informational and/or
      marketing messages associated with certain other EmpiRx Health text
      message programs. If you opt-in to receive text messages for a particular
      category of communication preferences, you will receive text messages
      consistent with your communication preferences as further described in the
      <a routerLink="/privacy-policy" target="_blank">Privacy Statement</a>.
    </p>
    <p><strong>NO MEDICAL ADVICE </strong></p>
    <p>
      The information provided on the Website and in the Portal is for
      informational purposes or general guidance only and does not constitute
      medical or other professional advice. We do not warrant or guarantee the
      accuracy, completeness, adequacy, or currency of the provided information,
      nor do we endorse any views or products. Information on the Website and
      Portal is not a substitute for medical or other professional advice, and
      it is important that no medical or other professional decisions should be
      made without first consulting a personal physician, pharmacists or other
      applicable professional.
    </p>
    <p><strong>USE RESTRICTIONS </strong></p>
    <p>
      You agree that you will not (and will not attempt to): (a) access or use
      the Website or Portal in any manner or for any purpose not expressly
      authorized by these Terms of Use; (b) distribute, sublicense, lease, rent,
      loan, or otherwise use or make any component of the Website or Portal
      available for use by third parties, including as part of a service bureau,
      outsourcing, or external consulting arrangement; (c) allow any other
      person or entity to use your login credentials to access, view, or use any
      component of the Website or Portal or for posting, copying, extracting,
      downloading, viewing, transmitting, or receiving data of any kind; (d)
      access any component available through the Website or Portal via remote
      access through interfaces or automated means not approved in writing by
      us; (e) collect, compile, or otherwise attempt to “screen scrape,” “data
      mine” or “harvest” any component of the Website or Portal, including
      through the creation of any duplicate or derivative data store; (f)
      deactivate, bypass, or circumvent any access controls or security measures
      for the Website or Portal; (g) gain unauthorized access to the Website or
      Portal or another site user’s network, systems, or data; (h) engage in any
      activity or use any device, software, or routine, or introduce any virus,
      Trojan horse, spyware, adware, or other malicious code, that interferes
      with a user’s access to the Website or Portal or the proper operation of
      this Website and Portal; (i) engage in any activity that disrupts or
      impairs the performance of the Website or Portal; (j) access or use the
      Website or Portal in violation of any applicable laws or regulations, the
      intellectual property or other rights of any third party, or any
      contractual or legal duty or obligation including in a manner prohibited
      by these Terms of Use; (k) reverse engineer, decompile, disassemble, or
      otherwise attempt to derive the source code or underlying programming,
      models, databases, or database structures for the Website or Portal; or
      (l) remove or obscure any copyright, trademark, confidentiality, or other
      Website or Portal notices, terms, or disclosures. We reserve the right to
      suspend or terminate Website or Portal access to address non-compliance
      with these Terms of Use.
    </p>
    <p><strong>DISCLAIMER OF WARRANTIES </strong></p>
    <p>
      THE WEBSITE, WEBSITE CONTENT AND PORTAL ARE PROVIDED ON AN “AS IS BASIS,
      AND ALL USE OF THE WEBSITE AND THE WEBSITE CONTENT IS AT YOUR OWN RISK. TO
      THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, WE MAKE NO
      REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, REGARDING
      THE WEBSITE, WEBSITE CONTENT, PORTAL OR YOUR ACCESS TO OR USE THEREOF. WE
      HEREBY DISCLAIM ANY AND ALL IMPLIED AND STATUTORY WARRANTIES OF ANY KIND,
      INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, FITNESS
      FOR A PARTICULAR PURPOSE, PERFORMANCE, TITLE, QUIET ENJOYMENT,
      NON-INFRINGEMENT, CUSTOM, AND USAGE OF TRADE. WITHOUT LIMITING THE
      FOREGOING, WE DO NOT REPRESENT OR WARRANT THAT THE WEBSITE, THE WEBSITE
      CONTENT OR PORTAL WILL BE ACCURATE, COMPLETE, UP-TO-DATE, OR RELIABLE;
      THAT ACCESS TO OR USE OF THE WEBSITE OR PORTAL WILL BE UNINTERRUPTED,
      SECURE, OR ERROR-FREE; THAT THE WEBSITE, WEBSITE CONTENT, PORTAL OR ANY
      PORTION THEREOF WILL NOT CHANGE OR BE DISCONTINUED; THAT ERRORS OR DEFECTS
      WILL BE CORRECTED; THAT THE WEBSITE, WEBSITE CONTENT OR PORTAL WILL BE
      FREE FROM COMPUTER VIRUSES OR OTHER HARMFUL COMPONENTS; OR THAT ACCESS TO
      OR USE OF THE WEBSITE, THE WEBSITE CONTENT OR PORTAL IS LAWFUL IN ANY
      PARTICULAR JURISDICTION. NO SUPPLIER TO US HAS ANY WARRANTY, SUPPORT, OR
      OTHER OBLIGATIONS TO YOU IN CONNECTION WITH YOUR USE OF THIS WEBSITE. YOU
      ASSUME THE ENTIRE OBLIGATION AND COST OF ANY AND ALL NECESSARY COMPUTER,
      MOBILE DEVICE, AND NETWORK SERVICING, REPAIR, AND CORRECTION. IF YOU ARE
      DISSATISFIED WITH THE WEBSITE, WEBSITE CONTENT, OR PORTAL YOUR SOLE AND
      EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE WEBSITE. SOME JURISDICTIONS
      DO NOT ALLOW THE DISCLAIMER OF IMPLIED OR CERTAIN OTHER WARRANTIES, SO THE
      ABOVE DISCLAIMERS MAY NOT APPLY TO YOU TO THE EXTENT SUCH A JURISDICTION’S
      LAW APPLIES AND LIMITS SUCH DISCLAIMERS.
    </p>
    <p><strong>LIMITATION OF LIABILITY </strong></p>
    <p>
      UNDER NO CIRCUMSTANCES SHALL WE BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
      CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE, OR OTHER DAMAGES OR LOSSES OF
      ANY KIND ARISING OUT OF OR DIRECTLY OR INDIRECTLY RELATED TO THE WEBSITE,
      THE WEBSITE CONTENT, PORTAL, YOUR ACCESS TO OR USE THEREOF, OUR
      PERFORMANCE, NON-PERFORMANCE, ACTS, OR OMISSIONS IN CONNECTION THEREWITH,
      THE <a routerLink="/privacy-policy" target="_blank">PRIVACY STATEMENT</a>,
      OR THESE TERMS OF USE, EVEN IF FORESEEABLE, AND EVEN IF WE HAVE BEEN
      ADVISED OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. IN ANY
      EVENT, UNDER NO CIRCUMSTANCES SHALL OUR TOTAL, CUMULATIVE LIABILITY TO YOU
      FOR ANY AND ALL DAMAGES, LOSSES, CLAIMS, AND CAUSES OF ACTION EXCEED
      TWENTY-FIVE DOLLARS ($25.00). IN NO WAY LIMITING THE FOREGOING, WE ASSUME
      NO OBLIGATION AND SHALL HAVE NO LIABILITY ARISING OUT OF OR DIRECTLY OR
      INDIRECTLY RELATED TO ANY COMMUNICATION ERRORS, SERVICE INTERRUPTIONS, OR
      WEBSITE MALFUNCTIONS; THE CONDUCT OR CONTENT OF ANY WEBSITE USER; COMPUTER
      VIRUSES OR OTHER HARMFUL COMPONENTS; LOSS OR CORRUPTION OF DATA; DAMAGE TO
      YOUR COMPUTER, MOBILE DEVICE, OR NETWORK; MALICIOUS ACTS OF THIRD PARTIES;
      FORCE MAJEURE EVENTS; BUSINESS INTERRUPTION OR DOWNTIME; LOSS OF BUSINESS,
      PROFITS, OR GOODWILL; OR ACTIONS TAKEN OR NOT TAKEN IN CONNECTION WITH
      INVESTIGATIONS, DEMANDS, OR CLAIMS BY US, INTELLECTUAL PROPERTY OWNERS,
      LAW ENFORCEMENT, GOVERNMENTAL AUTHORITIES, OR THIRD PARTIES. THE
      LIMITATIONS ON LIABILITY IN THIS SECTION SHALL APPLY TO THE FULLEST EXTENT
      PERMISSIBLE UNDER APPLICABLE LAW, REGARDLESS OF THE NUMBER OF INCIDENTS OR
      CLAIMS, AND REGARDLESS OF THE NATURE OF THE DAMAGE, LOSS, CLAIM, OR CAUSE
      OF ACTION (WHETHER IN CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY,
      INDEMNITY, OR OTHERWISE). THE LIMITATIONS ON LIABILITY IN THIS SECTION ARE
      AN ESSENTIAL PART OF THESE TERMS OF USE, AND SHALL BE VALID AND BINDING
      EVEN IF ANY REMEDY IS DEEMED TO FAIL OF ITS ESSENTIAL PURPOSE. SOME
      JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF INCIDENTAL, CONSEQUENTIAL, OR
      CERTAIN OTHER DAMAGES, SO THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU TO THE
      EXTENT SUCH A JURISDICTION’S LAW APPLIES AND LIMITS SUCH EXCLUSIONS.
    </p>
    <p>
      <strong>NOTICE OF COPYRIGHT INFRINGEMENT </strong><br />
      If you believe that your work has been copied and is accessible on the
      Website in a way that constitutes copyright infringement, please provide
      our designated Copyright Agent identified below with the following
      information:
    </p>
    <ol>
      <li>
        identification of the copyrighted work claimed to have been infringed;
      </li>
      <li>
        identification of the allegedly infringing material on the Website that
        is requested to be removed;
      </li>
      <li>
        your name, address and daytime telephone number, and an e-mail address
        if available, so that we may contact you if necessary;
      </li>
      <li>
        a statement that you have a good faith belief that the use of the
        copyrighted work is not authorized by the copyright owner, its agent, or
        the law;
      </li>
      <li>
        a statement that the information in the notification is accurate, and
        under penalty of perjury, that the signatory is authorized to act on
        behalf of the owner of an exclusive copyright right that is allegedly
        infringed; and
      </li>
      <li>
        a physical or electronic signature of the copyright owner or the person
        authorized on the owner’s behalf to assert infringement of copyright and
        to submit the statement.
      </li>
    </ol>
    <p>
      Contact Information for our Copyright Agent: via email:
      <a href="mailto:privacy@empirxhealth.com">privacy@empirxhealth.com</a> or
      via mail: EmpiRx Health, LLC, ATTN: Privacy Officer, 155 Chestnut Ridge
      Road, Montvale, NJ 07645.
    </p>
    <p>
      We will remove any content that infringes the copyright of any person
      under the laws of the United States upon receipt of information as set
      forth in Subsections (a) – (f) above, and may, in our sole discretion if
      we deem it appropriate, terminate access rights of any user who we
      reasonably believe violates any United States copyright laws.
    </p>
    <p><strong>GOVERNING LAW </strong></p>
    <p>
      These Terms of Use shall be governed by and construed in accordance with
      the laws of the State of New Jersey, excluding its conflict of law rules.
    </p>
    <p>
      <strong>JURISDICTION; VENUE </strong><br />
      Any dispute, claim, action or proceeding arising out of or related to the
      Website, Website Content, Portal,
      <a routerLink="/privacy-policy" target="_blank">Privacy Statement</a>, or
      these Terms of Use, or the interpretation or enforcement hereof, whether
      at law or in equity, shall be brought only in the state courts located in
      Bergen County, New Jersey or, if proper and exclusive federal subject
      matter jurisdiction exists, the District Court of New Jersey in Newark,
      New Jersey. Each party hereby submits to the personal jurisdiction and
      consents to the exclusive venue of such courts and waives any objections
      thereto. Notwithstanding the foregoing, in connection with asserting or
      protecting our intellectual property or other legal rights or business
      interests, we retain the right to seek temporary or permanent injunctive
      or other non-monetary equitable relief against you in any court of
      competent jurisdiction.
    </p>
    <p>
      <strong>NO WAIVER </strong><br />
      Any waiver by us must be express and in writing, must be directed
      specifically by us to you, and must be signed by our duly authorized
      representative. Our failure to enforce any provision of these Terms of Use
      or to respond to a breach by you or third parties shall not in any way
      limit or waive our right to do so, including without limitation our right
      to enforce subsequently any provision of these Terms of Use, or to assert
      our rights with respect to the same or similar breaches.
    </p>
    <p>
      <strong>ENTIRE AGREEMENT </strong><br />
      Unless otherwise specified herein, these Terms of Use and the
      <a routerLink="/privacy-policy" target="_blank">Privacy Statement</a>
      incorporated herein constitute the entire agreement between you and us
      with respect to the subject matter hereof, and supersede all prior and
      contemporaneous agreements and understandings, whether written or oral,
      concerning the subject matter hereof.
    </p>
    <p>
      <strong>SEVERABILITY If </strong><br />
      any portion of these Terms of Use or the
      <a routerLink="/privacy-policy" target="_blank">Privacy Statement</a> is
      held invalid or unenforceable under applicable law, that portion shall be
      construed in a manner consistent with applicable law to accomplish, as
      nearly as possible, the objective thereof, or severed from the document if
      and solely to the limited extent such construction is not possible, and
      the remaining portion of these Terms of Use and the
      <a routerLink="/privacy-policy" target="_blank">Privacy Statement</a>
      shall remain in full force and effect.
    </p>
  </div>
</section>
