/**
 * @file pre-complete-profile.component.ts
 */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompleteProfileService } from './complete-profile.service';
import { MultilingualService } from '../core/services/multilingual/multilingual.service';
import { TokenService } from '../core/services/token/token.service';

@Component({
  selector: 'app-pre-complete-profile',
  templateUrl: './pre-complete-profile.component.html',
  styleUrls: ['./pre-complete-profile.component.scss']
})
export class PreCompleteProfileComponent implements OnInit {

  constructor(
    private readonly active: ActivatedRoute,
    private readonly router: Router,
    private readonly tokenService: TokenService,
    private readonly multilingualService: MultilingualService,
    private readonly completeProfileService: CompleteProfileService,
  ) {
    active.fragment.subscribe((fragment) => {
      if (fragment) {
        const urlSearchParams = new URLSearchParams(fragment);
        this.completeProfileService.ssoToken = urlSearchParams.get('sso_token') || '';
        this.completeProfileService.user = {
          email: urlSearchParams.get('email') || '',
          dateOfBirth: urlSearchParams.get('dateOfBirth') || '',
          firstName: urlSearchParams.get('firstName') || '',
          lastName: urlSearchParams.get('lastName') || '',
          contactNumber: urlSearchParams.get('contactNumber') || '',
          cardId: urlSearchParams.get('cardId') || '',
          clientId: urlSearchParams.get('clientId') || '',
          personCode: urlSearchParams.get('personCode') || '',
          userName: urlSearchParams.get('usernm') || '',
        };

        if (!this.completeProfileService.ssoToken) {
          this.router.navigate(['/main']);
        } else {
          this.tokenService.setSSOToken(this.completeProfileService.ssoToken);
          this.router.navigate(['complete-profile']);
        }
      }
    });
  }

  ngOnInit(): void {
  }

}
