/**
 * @file token.service.ts
 */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private SSO_TOKEN = 'sso_token';
  private ID_TOKEN = 'id_token';
  private BENECARD_ACCESS_TOKEN = 'access_token';
  private EMAIL = 'email';

  constructor() { }

  public setSSOToken(ssoToken: string): void {
    localStorage.setItem(this.SSO_TOKEN, ssoToken);
  }

  public get ssoToken(): string | null {
    return localStorage.getItem(this.SSO_TOKEN);
  }

  public setAzureIdToken(idToken: string): void {
    localStorage.setItem(this.ID_TOKEN, idToken);
  }

  public get azureIdToken(): string | null {
    return localStorage.getItem(this.ID_TOKEN);
  }

  public setBenecardAccessToken(accessToken: string): void {
    localStorage.setItem(this.BENECARD_ACCESS_TOKEN, accessToken);
  }

  public get benecardAccessToken(): string | null {
    return localStorage.getItem(this.BENECARD_ACCESS_TOKEN);
  }

  public setSSOEmail(email: string): void {
    localStorage.setItem(this.EMAIL, email);
  }

  public get ssoEmail(): string | null {
    return localStorage.getItem(this.EMAIL);
  }

  public get multilingualToken(): string {
    return '';
  }
}
