/**
 * @file complete-profile.service.ts
 */
import { Injectable } from '@angular/core';
import { User } from '../interfaces/user.interface';

@Injectable({
  providedIn: 'root'
})
export class CompleteProfileService {

  ssoToken = '';
  user!: User;

  constructor() { }
}
