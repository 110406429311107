/**
 * @file member.service.ts
 */
import { Injectable } from '@angular/core';
import { HttpService } from '../../core/services/http/http.service';
import { Observable } from 'rxjs';
import * as moment from 'moment-timezone';
import { PricingResponse } from '../../interfaces/pricing-response.interface';
import { FamilyCoverageResponse } from '../../interfaces/family-coverage-response.interface';
import { MedicationHistoryResponse } from '../../interfaces/medication-history-response.interface';
import { PharmacySearchResponse } from '../../interfaces/pharmacy-search-response.interface';
import { PharmacySearchRequest } from 'src/app/interfaces/request/pharmacy-search-request.interface';
import { UserService } from '../user/user.service';
import { AccountBalanceResponse } from 'src/app/interfaces/account-balance-response.interface';
import { APIConstants } from '../services.constants';
import { RequestOptions } from '../../interfaces/request-options.interface';
import { DrugPricingRequest } from '../../interfaces/request/drug-pricing-request.interface';
import { ClaimService } from '../claim/claim.service';
import { User } from '../../interfaces/user.interface';
import { PHIInfo } from '../../interfaces/phi-info.interface';
import { NotificationsBody } from '../../interfaces/notifications-body.interface';
import { ContactInfo } from 'src/app/interfaces/contact-info.interface';
import { CompleteProfileService } from 'src/app/pre-complete-profile/complete-profile.service';
import { Prescription } from 'src/app/interfaces/prescription.interface';
import { DrugDetailRequest } from 'src/app/interfaces/drug-detail-request.interface';

@Injectable({
  providedIn: 'root'
})
export class MemberService {
  user!: User;

  constructor(
    private readonly httpService: HttpService,
    private readonly userService: UserService,
    private readonly claimService: ClaimService,
    private readonly completeProfileService: CompleteProfileService
  ) { }

  public drugPricing(
    req: DrugPricingRequest
  ): Observable<PricingResponse> {
    return this.httpService.get<PricingResponse>(
      `${APIConstants.MEMBER_SERVICES.GET_DRUG_PRICING}?cardId=${this.userService.eligibilityVerificationResponse ?.cardId}&clientId=${this.userService.eligibilityVerificationResponse ?.clientId}&personCode=${this.userService.eligibilityVerificationResponse ?.personCode}${this.getDrugPricingParams(req)}`
    );
  }

  public familyCoverage(cardId: string, clientId: string): Observable<FamilyCoverageResponse> {
    return this.httpService.get<FamilyCoverageResponse>(`${APIConstants.MEMBER_SERVICES.GET_FAMILY_COVERAGE}?cardId=${cardId}&clientId=${clientId}`);
  }

  public medicationHistory(): Observable<MedicationHistoryResponse> {
    return this.httpService.get<MedicationHistoryResponse>(`${APIConstants.MEMBER_SERVICES.GET_MEDICATION_HISTORY}?cardId=${this.userService.eligibilityVerificationResponse ?.cardId}&clientId=${this.userService.eligibilityVerificationResponse ?.clientId}&personCode=${this.userService.eligibilityVerificationResponse ?.personCode}&startDate=${this.claimService.formatDateRFC3339(this.claimService.getPreviousYear(new Date(), 1))}&endDate=${this.claimService.formatDateRFC3339(new Date())}`);
  }

  public pharmacySearch(filter: PharmacySearchRequest): Observable<PharmacySearchResponse> {
    if (!(filter.city.trim() && filter.state.trim())) {
      filter.city = '';
      filter.state = '';
    }
    if (!(filter.zipCode.trim() && filter.radius.trim())) {
      filter.zipCode = '';
      filter.radius = '';
    }
    return this.httpService.get<PharmacySearchResponse>(`${APIConstants.MEMBER_SERVICES.GET_PHARMACY_SEARCH}?cardId=${this.userService.eligibilityVerificationResponse ?.cardId}&clientId=${this.userService.eligibilityVerificationResponse ?.clientId}&personCode=${this.userService.eligibilityVerificationResponse ?.personCode}${this.getSearchParams(filter)}`);
  }

  public getAccountBalance(): Observable<AccountBalanceResponse> {
    return this.httpService.get<AccountBalanceResponse>(`${APIConstants.MAIL_ORDER_ACCOUNT_SERVICE.GET_MAIL_ACCOUNT_BAL}?cardId=${this.userService.eligibilityVerificationResponse ?.cardId}&clientId=${this.userService.eligibilityVerificationResponse ?.clientId}&personCode=${this.userService.eligibilityVerificationResponse ?.personCode}`);
  }

  public getMemberDetails(userName: string): Observable<User> {
    return this.httpService.get<User>(`${APIConstants.MEMBER_SERVICES.GET_DETAILS}?userName=${userName}`);
  }

  public getSsoMemberDetails(clientId?: string, cardId?: string, dateOfBirth?: string, firstName?: string, lastName?: string): Observable<User> {
    return this.httpService.get<User>(`${APIConstants.MEMBER_SERVICES.GET_SSO_MEMBER_DETAILS}?clientId=${clientId}&cardId=${cardId}&dateOfBirth=${dateOfBirth}&firstName=${firstName}&lastName=${lastName}`);
  }

  public getUserName(oid: string): Observable<string> {
    return this.httpService.get<string>(`${APIConstants.MEMBER_SERVICES.GET_USER_NAME}?objectId=${oid}`);
  }

  public sendOTP(email: string, username: string | undefined): Observable<string> {
    return this.httpService.get<string>(`${APIConstants.MEMBER_SERVICES.SEND_OTP}?email=${email}&username=${username}`);
  }

  public verifyOTP(otp: number, username: string | undefined): Observable<string> {
    return this.httpService.get<string>(`${APIConstants.MEMBER_SERVICES.VERIFY_OTP}?username=${username}&otp=${otp}`);
  }

  public updateMemberDetails(user: User): Observable<string> {
    return this.httpService.post<string>(`${APIConstants.MEMBER_SERVICES.UPDATE_DETAILS}`, user);
  }

  public updateMemberCardId(username: string, cardId: string) {
    return this.httpService.post<string>(`${APIConstants.MEMBER_SERVICES.UPDATE_CARDID}?username=${username}&cardid=${cardId}`);
  }

  public getContactSSOInfo(): Observable<ContactInfo>{
    return this.httpService.get<ContactInfo>(`${APIConstants.USER_MGMT_SERVICES.GET_CONTACT_INFO}?clientId=${this.completeProfileService.user?.clientId}&cardId=${this.completeProfileService.user?.cardId}&personCode=${this.completeProfileService.user?.personCode}`);
  }
  public getContactInfo(): Observable<ContactInfo>{
    return this.httpService.get<ContactInfo>(`${APIConstants.USER_MGMT_SERVICES.GET_CONTACT_INFO}?clientId=${this.userService.eligibilityVerificationResponse?.clientId}&cardId=${this.userService.eligibilityVerificationResponse?.cardId}&personCode=${this.userService.eligibilityVerificationResponse?.personCode}`);
  }

  public UpdateSsoMemberDetail(user: User): Observable<string> {
    return this.httpService.post<string>(`${APIConstants.MEMBER_SERVICES.UPDATE_SSO_USER_DETAILS}`, user);
  }

  public completeProfile(user: User): Observable<string> {
    return this.httpService.post<string>(`${APIConstants.MEMBER_SERVICES.COMPLETE_PROFILE}`, user);
  }

  public getPHISettings(): Observable<PHIInfo> {
    return this.httpService.get<PHIInfo>(`${APIConstants.MEMBER_SERVICES.GET_PHI_SETTINGS}?cardId=${this.userService.eligibilityVerificationResponse ?.cardId}&clientId=${this.userService.eligibilityVerificationResponse ?.clientId}&personCode=${this.userService.eligibilityVerificationResponse ?.personCode}`);
  }

  public updatePHISettings(phiInfo: PHIInfo): Observable<void> {
    return this.httpService.put<void>(`${APIConstants.MEMBER_SERVICES.GET_PHI_SETTINGS}?cardId=${this.userService.eligibilityVerificationResponse ?.cardId}&clientId=${this.userService.eligibilityVerificationResponse ?.clientId}&personCode=${this.userService.eligibilityVerificationResponse ?.personCode}`, phiInfo, { responseType: 'text' } as RequestOptions);
  }

  public getNotificationSettings(): Observable<NotificationsBody> {
    return this.httpService.get<NotificationsBody>(`${APIConstants.MEMBER_SERVICES.NOTIFICATION_SETTINGS}?cardId=${this.userService.eligibilityVerificationResponse ?.cardId}&clientId=${this.userService.eligibilityVerificationResponse ?.clientId}&personCode=${this.userService.eligibilityVerificationResponse ?.personCode}`);
  }

  public updateNotificationSettings(notification: NotificationsBody): Observable<NotificationsBody> {
    return this.httpService.put<NotificationsBody>(`${APIConstants.MEMBER_SERVICES.NOTIFICATION_SETTINGS}?cardId=${this.userService.eligibilityVerificationResponse ?.cardId}&clientId=${this.userService.eligibilityVerificationResponse ?.clientId}&personCode=${this.userService.eligibilityVerificationResponse ?.personCode}`, notification, { responseType: 'text' } as RequestOptions);
  }

  public sendMail(email: string): Observable<void> {
    return this.httpService.post<void>(`${APIConstants.MEMBER_SERVICES.SEND_EMAIL}`, { ToEmail: email });
  }

  public getPrescriptionPdf(prescription: Prescription): Observable<Blob> {
    // let currentDatetime = new Date();
    let formattedDate = moment().format('MM-DD-YYYY');
    let patientDateOfBirth = moment(new Date(prescription.patientDateOfBirth)).format('MM-DD-YYYY');

    let dispensedDate = moment(new Date(prescription.dispensedDate)).format('MM-DD-YYYY');
    let rxDate = moment(new Date(prescription.rxDate)).format('MM-DD-YYYY');
    let expirationDate = moment(new Date(prescription.expirationDate)).format('MM-DD-YYYY');

    prescription.patientDateOfBirth = patientDateOfBirth;
    prescription.dispensedDate = dispensedDate;
    prescription.rxDate = rxDate;
    prescription.expirationDate = expirationDate;
    return this.httpService.post<Blob>(`${APIConstants.MEMBER_SERVICES.GET_PRESCRIPTION_PDF}`, 
    { ...prescription, "timeStamp": formattedDate},
    { responseType: 'blob' } as RequestOptions
    );
  }

  public getDrugPdf(drugDetails: DrugDetailRequest | null): Observable<Blob> {
    let formattedDate = moment().format('MM-DD-YYYY');
    // let currentDatetime = new Date();
    // let formattedDate = currentDatetime.getFullYear() + "-" + (currentDatetime.getMonth() + 1) + "-" + currentDatetime.getDate() + " " + currentDatetime.getHours() + ":" + currentDatetime.getMinutes() + ":" + currentDatetime.getSeconds()
    return this.httpService.post<Blob>(`${APIConstants.MEMBER_SERVICES.GET_DRUG_PDF}`, 
    { ...drugDetails, "timestamp": formattedDate},
    { responseType: 'blob' } as RequestOptions
    );
  }

  public temporaryIdCard(personCode: string): Observable<Blob> {
    return this.httpService.get<Blob>(
      `${APIConstants.MEMBER_SERVICES.GET_TEMP_CARD}?cardId=${this.userService.eligibilityVerificationResponse ?.cardId}&clientId=${this.userService.eligibilityVerificationResponse ?.clientId}&personCode=${personCode}`,
      { responseType: 'blob' } as RequestOptions
    );
  }

  public explanationOfBenefits(selectedYear: number): Observable<Blob> {
    return this.httpService.get<Blob>(
      `${APIConstants.MEMBER_SERVICES.EOB}?cardId=${this.userService.eligibilityVerificationResponse ?.cardId}&clientId=${this.userService.eligibilityVerificationResponse ?.clientId}&personCode=${this.userService.eligibilityVerificationResponse ?.personCode}&year=${selectedYear}`,
      { responseType: 'blob' } as RequestOptions
    );
  }

  private getDrugPricingParams(filter: DrugPricingRequest): string {
    let params = '';
    if (!filter.radius) {
      filter.zipCode = undefined;
    }
    if (!filter.zipCode || filter.zipCode.length !== 5) {
      filter.zipCode = undefined;
      filter.radius = undefined;
    }
    if (!filter.city) {
      filter.state = undefined;
    }
    if (!filter.state) {
      filter.city = undefined;
    }
    if (filter.radius) {
      params += `&radius=${filter.radius}`;
    }
    if (filter.zipCode) {
      params += `&zipCode=${filter.zipCode}`;
    }
    if (filter.pharmacyName) {
      params += `&pharmacyName=${filter.pharmacyName}`;
    }
    if (filter.city) {
      params += `&city=${filter.city}`;
    }
    if (filter.state) {
      params += `&state=${filter.state}`;
    }
    if (filter.ndcTuple) {
      params += `&ndcTuple=${filter.ndcTuple};${filter.quantity};${filter.daysSupply}`;
    }
    return params;
  }

  private getSearchParams(filter: PharmacySearchRequest): string {
    let params = '';
    if (filter.radius) {
      params += `&radius=${filter.radius}`;
    }
    if (filter.zipCode) {
      params += `&zipCode=${filter.zipCode}`;
    }
    if (filter.pharmacyName) {
      params += `&pharmacyName=${filter.pharmacyName}`;
    }
    if (filter.city) {
      params += `&city=${filter.city}`;
    }
    if (filter.state) {
      params += `&state=${filter.state}`;
    }
    return params;
  }

}
