/**
 * @file user.service.ts
 */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../core/services/http/http.service';
import { EligibilityVerificationResponse } from '../../interfaces/eligibility-verification-response.interface';
import { TokenService } from '../../core/services/token/token.service';
import jwtDecode from 'jwt-decode';
import { FamilyCoverageResponse } from '../../interfaces/family-coverage-response.interface';
import { Member } from '../../interfaces/member.interface';
import { AccountBalanceResponse } from '../../interfaces/account-balance-response.interface';
import { APIConstants } from '../services.constants';
import { AuthService } from '../../core/services/auth/auth.service';
import { EligibilityVerificationResponseSSO } from '../../interfaces/eligibility-verification-response-sso.interface';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  userName: string | null = null;
  eligibilityVerificationResponse: EligibilityVerificationResponse | null = null;
  familyCoverageResponse: FamilyCoverageResponse | null = null;
  allFamilyCoverageResponse: FamilyCoverageResponse | null = null;
  member: Member | null = null;
  accountBalanceResponse: AccountBalanceResponse | null = null;
  isPHISettingVisible!: boolean;
  isMemberTermed = false;
  isMemberFutureEffective = false;

  constructor(
    private readonly httpService: HttpService,
    private readonly tokenService: TokenService,
    private readonly authService: AuthService,
  ) { }

  getEmail(): string {
    try {
      if (this.tokenService.azureIdToken) {
        return jwtDecode<{ emails: string[]; }>(this.tokenService.azureIdToken || '').emails[0];
      } else {
        return this.tokenService.ssoEmail || jwtDecode<{ email: string; }>(this.tokenService.ssoToken || '').email;
      }
    } catch (error) {
      this.authService.logout();
      return '';
    }
  }

  getFirstName(): string {
    try {
      return jwtDecode<{ given_name: string; }>(this.tokenService.ssoToken || '').given_name;
    } catch (error) {
      this.authService.logout();
      return '';
    }
  }

  getClientId(): string {
    try {
      return jwtDecode<{ prn: string; }>(this.tokenService.ssoToken || '').prn;
    } catch (error) {
      this.authService.logout();
      return '';
    }
  }

  getCardId(): string {
    try {
      return jwtDecode<{ CardId: string; }>(this.tokenService.ssoToken || '').CardId;
    } catch (error) {
      this.authService.logout();
      return '';
    }
  }

  getPersonCode(): string {
    try {
      return jwtDecode<{ PersonCode: string; }>(this.tokenService.ssoToken || '').PersonCode;
    } catch (error) {
      this.authService.logout();
      return '';
    }
  }

  getSequenceCode(): string {
    try {
      return jwtDecode<{ SequenceCode: string; }>(this.tokenService.ssoToken || '').SequenceCode;
    } catch (error) {
      this.authService.logout();
      return '';
    }
  }

  getLastName(): string {
    try {
      return jwtDecode<{ family_name: string; }>(this.tokenService.ssoToken || '').family_name;
    } catch (error) {
      this.authService.logout();
      return '';
    }
  }

  getdob(): string {
    try {
      return jwtDecode<{ birthdate: string; }>(this.tokenService.ssoToken || '').birthdate;
    } catch (error) {
      this.authService.logout();
      return '';
    }
  }

  getObjectId(): string {
    try {
      return jwtDecode<{ oid: string; }>(this.tokenService.azureIdToken || '').oid;
    } catch (error) {
      this.authService.logout();
      return '';
    }
  }

  getUserEligbility(): Observable<EligibilityVerificationResponse> {
    return this.httpService.get(`${APIConstants.USER_MGMT_SERVICES.GET_ELIGIBILITY_VERIFICATION}?username=${this.userName}`);
  }

  getUserEligbilitySSO(clientId: string, cardId: string, personCode: string, sequenceCode: string): Observable<EligibilityVerificationResponseSSO> {
    let strURL;
    if (personCode != "")
    {
         strURL = "clientId=" + clientId + "&cardId=" + cardId + "&personCode=" + personCode; //+ "&sequenceCode=" + SequenceCode
    }
    else if (sequenceCode != "")
    {
        strURL = "clientId=" + clientId + "&cardId=" + cardId + "&sequenceCode=" + sequenceCode;
    }
    return this.httpService.get(`${APIConstants.USER_MGMT_SERVICES.GET_ELIGIBILITY_VERIFICATION_SSO}?${strURL}`);
  }
}
