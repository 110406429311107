/**
 * @file app-routing.module.ts
 */
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule, TitleStrategy } from '@angular/router';
import { AzureB2CAuthGuard } from './core/guards/azure-b2c/azure-b2c-auth.guard';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { LegacyLoginComponent } from './legacy-login/legacy-login.component';
import { PreCompleteProfileComponent } from './pre-complete-profile/pre-complete-profile.component';
import { CompleteProfileComponent } from './complete-profile/complete-profile.component';
import { ForgotUsernameComponent } from './forgot-username/forgot-username.component';
import { SsoErrorComponent } from './sso-error/sso-error.component';
import { CustomTitleStrategy } from './services/title-strategy';

export const routes: Routes = [
  { path: '', redirectTo: 'main', pathMatch: 'full' },
  {
    path: 'main',
    loadChildren: () => import('./main/main.module').then(m => m.MainModule),
    canActivate: [AzureB2CAuthGuard]
  },
  {
    path: 'guest-login',
    loadChildren: () => import('./guest-user/guest-user.module').then(m => m.GuestUserModule)
  },
  { path: 'terms-of-use', component: TermsOfUseComponent, pathMatch: 'full' },
  { path: 'privacy-policy', component: PrivacyPolicyComponent, pathMatch: 'full' },
  { path: 'legacy-login', component: LegacyLoginComponent, pathMatch: 'full' },
  { path: 'pre-complete-profile', component: PreCompleteProfileComponent, pathMatch: 'full' },
  { path: 'complete-profile', component: CompleteProfileComponent, pathMatch: 'full' },
  {
    path: 'forgot-username',
    component: ForgotUsernameComponent,
    pathMatch: 'full', title: 'Forgot Username'
  },
  { path: 'Error', component: SsoErrorComponent, pathMatch: 'full' },
  { path: '**', redirectTo: 'main', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: TitleStrategy,
      useClass: CustomTitleStrategy
    }
  ]
})
export class AppRoutingModule { }
