import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CompleteProfileService } from '../pre-complete-profile/complete-profile.service';
import { Router } from '@angular/router';
import { MemberService } from '../services/member/member.service';
import { emailPattern } from '../main/main.constants';
import { ContactInfo } from '../interfaces/contact-info.interface';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-complete-profile',
  templateUrl: './complete-profile.component.html',
  styleUrls: ['./complete-profile.component.scss']
})
export class CompleteProfileComponent implements OnInit {

  userForm: UntypedFormGroup;
  isError = false;
  isLoading = false;
  mobileNumber = '';
  loaded = false;
  errorMsg!: string;
  @ViewChild('error', { read: TemplateRef }) errorTemplate!: TemplateRef<any>;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly router: Router,
    private readonly memberService: MemberService,
    private readonly snackBar: MatSnackBar,
    private readonly completeProfileService: CompleteProfileService
  ) {
    this.userForm = this.fb.group({
      email: [this.completeProfileService.user.email, [Validators.required, Validators.pattern(emailPattern)]],
      firstName: [this.completeProfileService.user.firstName, [Validators.required]],
      lastName: [this.completeProfileService.user.lastName, [Validators.required]],
      contactNumber: [this.mobileNumber, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      // mobileNumber: [this.completeProfileService.user.mobileNumber, [Validators.required,Validators.minLength(10), Validators.maxLength(10)]],
      dateOfBirth: [this.completeProfileService.user.dateOfBirth, [Validators.required]],
      cardId: [this.completeProfileService.user.cardId, [Validators.required]],
      clientId: [this.completeProfileService.user.clientId, [Validators.required]],
      personCode: [this.completeProfileService.user.personCode, [Validators.required]],
      // iAgreetotheTermsandConditions: [true, [Validators.required]],
      userName: [this.completeProfileService?.user?.userName, [Validators.required]],
      objectId: [''],
    });
  }

  ngOnInit(): void {
    this.getContactInfo();
   }

   getContactInfo():void{
    this.memberService.getContactSSOInfo().subscribe((data: ContactInfo) => {
      if (data && data.mobileNumber) {
        this.mobileNumber = data.mobileNumber;
        this.userForm.patchValue({
          contactNumber: data.mobileNumber, 
        });
      }
      this.loaded = true;
    }, () => {
      this.loaded = true;
      this.errorMsg = 'Failed to fetch the mobile number';
      this.snackBar.openFromTemplate(this.errorTemplate, {
        duration: 4000,
        verticalPosition: 'top',
        horizontalPosition: 'center'
      });
    });
   }
  complete(): void {
    this.isError = false;
    this.isLoading = true;
    this.memberService.UpdateSsoMemberDetail(this.userForm.value).subscribe(() => {
      this.isLoading = false;
      this.router.navigate(['/main']);
    }, () => {
      this.isError = true;
      this.isLoading = false;
    });
  }
}
